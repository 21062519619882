import React from 'react';
import './OptionSet.css';
import { isValidEmail } from '../utils/helpers';

const OptionSet = ({ optionSet, onOptionClick, isDisabled=false }) => {

  const heading = window.localStorage.getItem("username")

  return (
        <div className="option-set">
          <h1 className='selection-heading'>{!isValidEmail(heading) ? `Welcome ${heading}` : optionSet.heading}</h1>
          <div className="options">
            {optionSet.options.map((option, index) => (
              <button className="option-button" key={index} onClick={()=>onOptionClick(option.screen)} disabled={isDisabled}>
                {option.optionType}
              </button>
            ))}
          </div>
        </div>
   
  );
};

export default OptionSet;
