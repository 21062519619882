import React, { useEffect, useRef, useState } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import {  Environment, Center } from '@react-three/drei'
import { easing } from 'maath'
import * as THREE from 'three';
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment';
import Model from './Model'
import { getTexture } from '../lib/textureCreation'
import rotateButton from '../assets/images/rotate_button.svg'
import resetRotationButton from '../assets/images/reset_rotation.svg'
import { appColors } from '../utils/studioSettings';
import "./Stage.css"

export const Stage = ({ position = [0, 0, 2.5], fov = 25, design, selections, selectedDesign, selectedInputOptions, studio=false}) => {

    const gender = selections?.gender?.toLowerCase()
    const type = selections?.item?.toLowerCase()?.replace(/ /g, '_')
    const garment = selections?.garment?.toLowerCase()
    const keeper = selections?.shirt?.toLowerCase().includes('goalkeeper')
    const inputs = selectedInputOptions

    const [rotation, setRotation] = useState(0)
    const [intervalId, setIntervalId] = useState(null)

    const [texture, setTexture] = useState(null)

    const handleRotateLeft = () => {
        setRotation((prev) => prev - ((2*Math.PI)/13));
    }

    const handleRotateRight = () => {
        setRotation((prev) => prev + ((2*Math.PI)/13)); 
    }

    const handleResetRoation = () => {
        setRotation(0)
    }

    const startRotateLeft = () => {
        if (intervalId) return; 

        const id = setInterval(() => {
            setRotation((prev) => prev - ((2*Math.PI)/13));
        }, 150); 

        setIntervalId(id);
    };

    const startRotateRight = () => {
        if (intervalId) return;

        const id = setInterval(() => {
            setRotation((prev) => prev + ((2*Math.PI)/13)); 
        }, 150); 
        
        setIntervalId(id);
    };

    const stopRotate = () => {
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }
    };

    useEffect(()=>{

        async function setDesign() {

            const base64Texture = await getTexture(gender, type, garment, keeper, inputs, selectedDesign, design)

            if(base64Texture){
                const image = new Image();
                image.src = base64Texture;
                const newTexture = new THREE.Texture();
                newTexture.image = image;
                image.onload = function () {
                    newTexture.needsUpdate = true;
                };
                newTexture.flipY = false
                newTexture.encoding = THREE.sRGBEncoding;
                setTexture(newTexture)
            }
        }

        setDesign()

    }, [gender, type, garment, keeper, inputs, selectedDesign, design])


    return (
            <div className='stage'>
                
                <Canvas key={garment} shadows camera={{ position, fov }} gl={{ preserveDrawingBuffer: true }} eventSource={document.getElementById('root')} eventPrefix="client">
                
                    <directionalLight position={[-1, 1, 0.866]}  color={appColors.LIGHTING_COLOR} intensity={4.5}/>
                    <directionalLight position={[1, 1, 0.866]}  color={appColors.LIGHTING_COLOR} intensity={5.5}/>

                    <Environment
                            preset={null} 
                            files={null}  
                            background
                            scene={new THREE.Scene()}  
                            children={(scene) => {
                            const pmremGenerator = new THREE.PMREMGenerator(scene);
                            const envTexture = pmremGenerator.fromScene(new RoomEnvironment()).texture;
                            scene.environment = envTexture;
                            scene.background = envTexture;
                            }}
                        />

                    <CameraRig deltaRotation={rotation} keeper={keeper} garment={garment}>
                        <Center>
                            <Model gender={gender} type={type} garment={garment} keeper={keeper} newTexture={texture}/>
                        </Center>
                    </CameraRig>
                </Canvas>
                
                {studio && <div>
                    <img className='rotate-button-left' src={rotateButton} alt="rotate left" onClick={handleRotateLeft}
                    onMouseDown={startRotateLeft} onMouseUp={stopRotate} onMouseLeave={stopRotate} 
                    onTouchStart={startRotateLeft} onTouchEnd={stopRotate} onTouchCancel={stopRotate}
                    />

                    <img className='rotate-button-right' src={rotateButton} alt="rotate right" onClick={handleRotateRight}
                    onMouseDown={startRotateRight} onMouseUp={stopRotate} onMouseLeave={stopRotate} 
                    onTouchStart={startRotateRight} onTouchEnd={stopRotate} onTouchCancel={stopRotate}/> 

                    <img className='reset-rotation-button' src={resetRotationButton} alt="rotate left" onClick={handleResetRoation}/> 
                </div>}

            </div>  
    )
}


function CameraRig({deltaRotation, children, keeper, garment }) {
    const group = useRef()

    useFrame((state, delta) => {

        let cameraZ = 1.7
        const screenWidth = window.innerWidth
        if(garment==='shirts' && screenWidth<=1024){
            cameraZ = 2.3
        }

        easing.damp3(state.camera.position, [0, 0, cameraZ], 0.25, delta)    
        easing.dampE(group.current.rotation, [0.2, deltaRotation, group.current.rotation.z], 0.25, delta)
    })

    return <group ref={group}>{children}</group>
}



