import React from 'react'
import './TermsAndConditions.css'
import backButton from '../../assets/images/back_button.svg'
import { useNavigate } from 'react-router-dom';

function PrivacyPolicy() {

    const navigate = useNavigate()

    const goBack = () => {
     navigate(-1)
    }

  return (
    <div className='terms-and-conditions'>
        <div className='mt-3 px-3 mb-3 back-button-bar'>
                <img className='back-button-mobile' src={backButton} alt="back" onClick={goBack}/> 
                <span className='back-button-text' onClick={goBack}>back</span>
        </div>

       <p>
        This Privacy Policy describes how Clubbie Ltd. ("Clubbie," "we," "us," or "our") collects, uses, and shares your personal information when you use our mobile application (the "App"). By using the App, you agree to the collection and use of information in accordance with this Privacy Policy. 
      </p>

         <br/>

      <p className='section-title'>
        1. Information We Collect       
      </p>
      <p >
        1.1 Information You Provide: When you create an account, we may collect personal information such as your name, email address, profile picture, location and age. 
      </p>
      <p >
        1.2 User Content: We collect content that you create, post, or share on the App, including videos and stats.     
      </p>
      <p >
        1.3 Usage Information: We collect information about how you use the App, such as the features you access, the content you view, and your interactions with other users. 
      </p>
      <p >
        1.4 Device Information: We may collect information about your mobile device, including the device type, operating system, and unique device identifiers. 
      </p>
      <p >
        1.5 Location Information: With your consent, we may collect precise location information from your mobile device when you use certain features of the App. 
      </p>

         <br/>

      <p className='section-title'>
        2. How We Use Your Information 
      </p>
      <p >
        2.1 We use the information we collect to: 
      </p>
      
        <li>
          Provide and improve the App and its features. 
        </li>
      
        <li>
          Customise your experience on the App. 
        </li>
      
        <li>
          Communicate with you about your account and the App. 
        </li>
      
        <li>
          Analyse usage trends and preferences. 
        </li>
      
        <li>
          Enforce our Terms and policies. 
        </li>
         <br/>

      <p className='section-title'>
        3. Information Sharing  
      </p>
      <p >
        3.1 We may share your information with third parties for the following purposes: 
      </p>
      
        <li>
          With service providers who help us operate the App and provide related services. 
        </li>
      
        <li>
          With your consent or at your direction. 
        </li>
      
        <li>
          In response to legal requests or obligations.         
        </li>
      
        <li>
          In connection with a merger, acquisition, or sale of assets.         
        </li>
        
        <br/>
        
      <p >
        3.2 We may also share aggregated or de-identified information that cannot reasonably be used to identify you. 
      </p>
         <br/>
      <p className='section-title'>
        4. Data Retention 
      </p>
      <p >
        4.1 We retain your personal information for as long as necessary to fulfil the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.  
      </p>
         <br/>
      <p className='section-title'>
        5. Your Choices 
      </p>
      <p >
        5.1 You may update or delete your account information at any time by accessing your account settings within the App. 
      </p>
      <p >
        5.2 You may also opt out of certain data collection and sharing practices, such as location tracking, by adjusting your device settings or by contacting us directly. 
      </p>
         <br/>
      <p className='section-title'>
        6. Children's Privacy 
      </p>
      <p >
        6.1 Clubbie acknowledges the importance of protecting the privacy of children. While the App is not intended for children under the age of 13, parents or legal guardians may create profiles for their children without any age restrictions.       
      </p>
      <p >
        6.2 When a parent or legal guardian creates a profile for a child, they are responsible for providing consent and ensuring that the child's personal information is handled in compliance with applicable laws.     
      </p>
      <p >
        6.3 Clubbie does not knowingly collect personal information from children without parental consent. If we become aware that we have inadvertently collected personal information from a child without parental consent, we will take steps to delete such information as soon as possible.       
      </p>
      <p >
        6.4 Parents or legal guardians may review, update, or delete their child's account information at any time by accessing the account settings within the App or by contacting us directly.       
      </p>
      <p >
        6.5 If you believe that we have collected personal information from a child without parental consent or if you have any questions or concerns about our practices regarding children's privacy, please contact us at hello@clubbie.com and we will promptly address any inquiries or requests related to children's privacy. 
      </p>
         <br/>
      <p className='section-title'>
        7. Security 
      </p>
      <p >
        7.1 We take reasonable measures to protect your personal information from unauthorised access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure. 
      </p>
         <br/>
      <p className='section-title'>
        8. Changes to This Privacy Policy 
      </p>
      <p >
        8.1 We reserve the right to update or change this Privacy Policy at any time. We will notify you of any changes by posting the new Privacy Policy on this page. Changes are effective immediately upon posting. 
      </p>
         <br/>
      <p className='section-title'>
        9. Contact Us 
      </p>
      <p >
        9.1 If you have any questions about this Privacy Policy, please contact us at hello@clubbie.com 
      </p>
         <br/>
      <p >
        This Privacy Policy was last updated on 15 April 2024. 
      </p>
         <br/>
    </div>
  )
}

export default PrivacyPolicy