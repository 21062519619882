import { useGLTF } from '@react-three/drei';
import { useRef } from 'react'
import { modelsInfo } from '../utils/models';

function Model({gender, type, garment, keeper, newTexture=null}) {
    const meshRef = useRef();
    const keys = modelsInfo

    const kit = keeper && type!=='training_kits' ? 'keeper' : 'player'
    const metaData = garment === 'shirts' ? keys[garment][kit][gender] : keys[garment]
    
    const { nodes, materials } = useGLTF(metaData.model)
    
    let materialClone;
    if (nodes && materials && newTexture) {
        materialClone = materials[metaData.material].clone();
        materialClone.map = newTexture
        materialClone.roughness = 1
        materialClone.needsUpdate = true
    }


    return (
        <>
            {
                nodes && materials &&
                <mesh ref={meshRef} 
                        castShadow 
                        geometry={nodes[metaData.node].geometry} 
                        material={materialClone} 
                        rotation={metaData.rotation} 
                        scale={[1, 1, 1]}>
                </mesh>
            }
        </>
    )
}

export default Model