import React, { useState,  useEffect, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './SavedDesigns.css'
import { getSavedDesigns } from '../lib/actions';
import PopUpOption from '../components/PopUpOption';
import OverlayLoader from '../components/OverlayLoader';
import { Col, Row } from 'react-bootstrap';
import { Stage } from '../components/Stage';
import SavedItemOptions from '../components/SavedItemOptions';
import { deleteDesign } from '../lib/actions';
import { STUDIO_STACK } from '../utils/screenNames';
import { addToCart } from '../redux/cartSlice';
import PopUpWithMsg from '../components/PopUpWithMsg';

function SavedDesigns() {

    const navigate = useNavigate()
    const location = useLocation();

    const dispatch = useDispatch()

    const [savedDesigns, setSavedDesigns] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [showCartPopUp, setShowCartPopUp] = useState(false)
    const [showDeletePopUp, setShowDeletePopUp] = useState(false)
    const [designIndex, setDesignIndex] = useState()

    const fetchSavedDesigns = useCallback(async () => {
        function onSuccess(designs) {
            setSavedDesigns(designs.savedDesigns)
            setIsLoading(false)
        }   

        function onFail(){
            setIsLoading(false)
        }
        
        setIsLoading(true)

        await getSavedDesigns(onSuccess, onFail)
    }, [setIsLoading])

    const handleEdit = useCallback((index) => {
        const designToEdit = savedDesigns[index]
        navigate(STUDIO_STACK.DESIGN_STUDIO, {state: {...designToEdit, index: index}})
    }, [savedDesigns, navigate])

    const deleteButtonPress = useCallback((index) => {
        setDesignIndex(index)
        setShowDeletePopUp(true)
    }, [setDesignIndex, setShowDeletePopUp])

    const handleDelete = useCallback(async (index)=> {
        function onSuccess(designs){
            setSavedDesigns(designs.savedDesigns)
            setIsLoading(false)
        }

        function onFail(){
            setIsLoading(false)
        }

        setShowDeletePopUp(false)
        setIsLoading(true)

        await deleteDesign(index, onSuccess, onFail)

    }, [setShowDeletePopUp, setIsLoading])

    const closeDeletePopUp = useCallback(() => {
        setShowDeletePopUp(false)
    }, [setShowDeletePopUp])

    const handleAddToCart = useCallback((index) => {
        const designToAdd = savedDesigns[index]
        const cartItem = {design: designToAdd.design, selections: designToAdd.selections, 
                        designSelection: designToAdd.designNumber, selectedInputOptions: designToAdd.specs}

        dispatch(addToCart(cartItem))

        setShowCartPopUp(true)
    }, [savedDesigns, dispatch, setShowCartPopUp])

    const closeCartPopUp = useCallback(() => {
        setShowCartPopUp(false)
    }, [setShowCartPopUp])

    const handleGoToCart = useCallback(() => {
        navigate(STUDIO_STACK.CART)
    }, [navigate])

    const handleBack = useCallback(() => {
        if(location?.state?.from === STUDIO_STACK.DESIGN_STUDIO){
            navigate(STUDIO_STACK.DESIGN_STUDIO, {state: {selections: location?.state?.selections}})
        }
        else{
            navigate(-1)
        }
    }, [location, navigate])


    useEffect(() => {

        fetchSavedDesigns()

      }, [fetchSavedDesigns]);

    return (
            <div className='saved-designs-page'>
                {isLoading ? <OverlayLoader /> : 
                    <div className='saved-designs'>
                        {savedDesigns?.length > 0 ? 
                            <Row>
                                {savedDesigns.map((savedDesign, index) => {

                                    return  <Col key={index} md={savedDesigns?.length<3 ? (savedDesigns?.length===1 ? 12 : 6) : 4}>
                                                <Row className='saved-design'>
                                                    <Col md={7}>
                                                        <Stage 
                                                            design={savedDesign.design} 
                                                            selections={savedDesign.selections} 
                                                            selectedDesign={savedDesign.designNumber}
                                                            selectedInputOptions={savedDesign.specs}
                                                            /> 
                                                        </Col>
                                                    <Col md={5}>
                                                        <SavedItemOptions addToCart={handleAddToCart} edit={handleEdit} deleteItem={deleteButtonPress} index={index}/>
                                                    </Col>
                                                </Row>                                        
                                            </Col>
                                })}
                            </Row>
                             :
                            <div className='p-5 nothing-saved-msg'>
                                <h3>No designs saved yet.</h3>
                                <p>Start creating your custom teamwear and save your favourites here!</p>
                                <PopUpOption text="Back" minWidth='150px' click={handleBack}/>
                            </div>
                        } 
                    </div>
                }

                {savedDesigns?.length > 0 && !isLoading &&
                  <div className='saved-designs-back-button'>
                    <PopUpOption text="Back" minWidth='150px' click={handleBack}/>
                  </div>}

                {showCartPopUp && <PopUpWithMsg 
                                    onOptionPress={handleGoToCart} 
                                    optionText={'Go to cart'} 
                                    popUpMsg={'Item added to cart'} 
                                    closePopUp={closeCartPopUp} />}

                {showDeletePopUp && <PopUpWithMsg 
                                    onOptionPress={() => handleDelete(designIndex)} 
                                    optionText={'Delete'} 
                                    popUpMsg={'Are you sure you want to delete this design?'} 
                                    closePopUp={closeDeletePopUp}
                                    isDelete={true} />}

            </div>
    )
}

export default SavedDesigns