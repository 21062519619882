import axios from "axios";
import { config } from "../utils/config";
import { designOptions, inputSubOptions } from "../utils/studioSettings";
import { defaultColors } from "../utils/studioSettings";
import {designs} from '../utils/designComponents'
const {createCanvas, loadImage} = require('canvas');


export async function getDesignDataAPI(selections, onSuccess, onFail) {
    try {
        const body = processSelectionBody(selections)

        const designs = await axios({
            method: 'post',
            url: `${config.API_BASE_URL}/design-studio/designs`,
            data: body,
            encoding: null
        })

        if (typeof onSuccess === 'function') onSuccess(designs.data)
    }

    catch (err) {
      if (typeof onFail === 'function') onFail()
    }
}

function processSelectionBody (selections) {
    const type = selections?.item?.toLowerCase()?.replace(/ /g, '_')
    let garment = selections?.garment?.toLowerCase()
    if(type==='training_kits'){
        garment = 'shirts'
    }
    const gender = selections?.gender?.toLowerCase()
    const keeper = selections?.shirt?.toLowerCase().includes('goalkeeper')

    return {garment, type, gender, keeper}
}

export async function getDesignData(onSuccess, onFail) {
    try{
        const designs = await getDesigns()

        if (typeof onSuccess === 'function') onSuccess(designs)
    }

    catch (err) {
    
      if (typeof onFail === 'function') onFail()
    }
}

async function getDesigns(){
    const designsObject = {}

    const garments = ['shirts', 'shorts', 'socks']

    for(let g = 0; g<garments.length; g++){

        const garment = garments[g];

        const folderName = `designs/${garment}`
        const designOptions = designs[garment]
        const designNumbers = Object.keys(designOptions)
        
        designsObject[garment] = {}

        for(let i = 0; i<designNumbers.length; i++){

            designsObject[garment][designNumbers[i]] = []

            const designComponents = designOptions[designNumbers[i]]

            for(let j=0; j<designComponents.length; j++) {
                const designInfo = {}

                const temp = await loadImage(`${folderName}/${designNumbers[i]}/${designComponents[j]}`)

                const canvas = createCanvas();
                const ctx = canvas.getContext('2d');
                
                canvas.width = temp.width;
                canvas.height = temp.height;
                
                ctx.drawImage(temp, 0, 0);
            
                const designBase64 = canvas.toDataURL('image/png')

                designInfo["component"] = designBase64

                const numericMatches = designComponents[j].match(/\d+/g);
                if (numericMatches) {
                    const numbers = numericMatches.map(Number);
                    const x = numbers[0];
                    const y = numbers[1];
                    const t = numbers[2];

                    designInfo["x"] = x
                    designInfo["y"] = y  
                    designInfo["type"] = t   
                }

                designsObject[garment][designNumbers[i]].push(designInfo)
            }
        }
    }

    return designsObject
}

export function getStudioDesignOptions (selections) {
    const {garment, type, gender, keeper} = processSelectionBody(selections)

    const kitType = keeper && type!=='training_kits' ? 'keeper' : 'player'
    
    const designs = garment === 'shirts' ? designOptions[kitType][garment]?.types?.options[type][gender] : designOptions[kitType][garment]?.types?.options["male"]

    const inputs = designOptions[kitType][garment]?.color?.options

    const selectedInputs = garment === "shirts" ? inputSubOptions[garment][type] : inputSubOptions[garment]

    return {designs, inputs, selectedInputs}
}

export async function placeOrder(cart, userInfo, onSuccess, onFail) {
    try {
        const orderItems = []

        for(let i = 0; i<cart.length; i++){
            const item = processCartItem(cart[i])
            orderItems.push(item)
        }

        const accessTokenObj = JSON.parse(window.localStorage.getItem("access"))
        const accessToken = accessTokenObj.token
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        }

        const body = {items: orderItems, userInfo: userInfo }

        await axios({
            method: 'post',
            url: `${config.API_BASE_URL}/design-studio/place-order`,
            data: body,
            headers: headers,
            encoding: null
        })

        if (typeof onSuccess === 'function') onSuccess()
    }

    catch (err) {
        if (typeof onFail === 'function') onFail()
    }

}

function processCartItem(cartItem){

    const {gender, ...selections} = processSelectionBody(cartItem.item.selections)
    const primaryColor = cartItem?.item.selectedInputOptions?.primary
    const secondaryColor = cartItem?.item.selectedInputOptions?.secondary
    const textColor = cartItem?.item.selectedInputOptions?.textColor

    const imageData = {}
    const textData = {}

    const logos = cartItem?.item.selectedInputOptions?.logos
    if (logos?.length){
        logos.forEach(logo => {
            if(logo.value) imageData[logo.variable] = logo.value
        })
    }

    const texts = cartItem?.item.selectedInputOptions?.text
    if (texts?.length){
        texts.forEach(text => {
            if(text.value) textData[text.variable] = text.value
        })
    }

    return {...selections, 

            design: cartItem?.item.designSelection,
            primaryColor: primaryColor ? primaryColor : defaultColors.primary,
            secondaryColor: secondaryColor ? secondaryColor : defaultColors.secondary,
            textColor: textColor ? textColor : defaultColors.textColor,
            
            imageData: imageData,
            textData: textData,

            quantity: cartItem?.quantity,
            info: selections.garment === "socks" ? [] : cartItem?.sizes
        }

}

export async function saveDesign(design, savedDesignIndex, accessTokenObj, onSuccess, onFail)  {
    try {
        const body = {design: design, savedDesignIndex: savedDesignIndex}

        const accessToken = accessTokenObj.token

        const headers = {
            'Authorization': `Bearer ${accessToken}`
        }

        await axios({
            method: 'post',
            url: `${config.API_BASE_URL}/design-studio/save-design`,
            data: body,
            headers: headers,
            encoding: null
        })

        if (typeof onSuccess === 'function') onSuccess()
    }

    catch (err) {
      if (typeof onFail === 'function') onFail()
    }
}

export async function getSavedDesigns(onSuccess, onFail) {
    try {

        const accessTokenObj = JSON.parse(window.localStorage.getItem("access"))
        const accessToken = accessTokenObj.token
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        }

        const savedDesigns = await axios({
            method: 'post',
            url: `${config.API_BASE_URL}/design-studio/saved-designs`,
            headers: headers,
            encoding: null
        })

        if (typeof onSuccess === 'function') onSuccess(savedDesigns.data)
    }

    catch (err) {
        if (typeof onFail === 'function') onFail()
    }
}

export async function deleteDesign(index, onSuccess, onFail) {
    try {

        const body = {
            designIndex: index
        }

        const accessTokenObj = JSON.parse(window.localStorage.getItem("access"))
        const accessToken = accessTokenObj.token
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        }

        const savedDesigns = await axios({
            method: 'post',
            url: `${config.API_BASE_URL}/design-studio/delete-design`,
            data: body,
            headers: headers,
            encoding: null
        })

        if (typeof onSuccess === 'function') onSuccess(savedDesigns.data)
    }

    catch (err) {
        if (typeof onFail === 'function') onFail()
    }
}
