import { STUDIO_STACK } from './screenNames'


export const optionSets = {
    s1: {
        heading: "Create or edit a design",
        options: [{optionType: "Create a design", screen: STUDIO_STACK.DESIGN_STUDIO}, 
                    {optionType: "My Designs", screen: STUDIO_STACK.SAVED_DESIGNS}]
    },
}

export const defaultSelections = {
    garment: "shirts",
    gender: "male",
    item: 'matchday_kits',
    shirt: 'player_kit'
}