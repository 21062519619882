import React from 'react'
import './Selection.css'
import OptionSet from '../components/OptionSet'
import { Placeholder } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { optionSets } from '../utils/optionSets';
import TopButtons from '../components/TopButtons';

function Selection() {

  const navigate = useNavigate()

  const handleOptionClick = (screenName) => {
    navigate(screenName)
  }

  return (
    <div className='selection-page'>
        <div className='selection-top-bar'>
            <p className='selection-page-heading'>Design Studio</p>
            <Placeholder />
        </div>
        
        <div className="options-holder">
            <OptionSet 
                optionSet={optionSets.s1}
                onOptionClick={handleOptionClick} 
            />
        </div>

        <TopButtons showSavedDesigns={false} />
    </div>
  )
}

export default Selection