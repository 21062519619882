import './TopButtons.css'
import { useNavigate, useLocation } from 'react-router-dom';
import { STUDIO_STACK, AUTHENTICATION_STACK } from '../utils/screenNames';
import React, { useCallback, useState } from 'react';
import PopUpWithMsg from './PopUpWithMsg';
import { clearSessionDesigns } from '../redux/sessionDesignSlice';
import { useDispatch } from 'react-redux';

function TopButtons ({selections, showSavedDesigns}) {

    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    const [logOutPopUp, setLogOutPopUp] = useState(false)

    const goToCart = useCallback(() => {
        navigate(STUDIO_STACK.CART)
      }, [navigate])
    
    const goToMyDesigns = useCallback(() => {
        navigate(STUDIO_STACK.SAVED_DESIGNS, {state: {selections: selections, from: location.pathname} })
    }, [navigate, location, selections])

    const handleLogOutPress = useCallback(() => {
        setLogOutPopUp(true)
    }, [setLogOutPopUp])

    const logOut = useCallback(() => {
        window.localStorage.setItem("username", "")
        window.localStorage.setItem("access", "")
        window.localStorage.setItem("refresh", "")
        dispatch(clearSessionDesigns())
        navigate(AUTHENTICATION_STACK.LOGIN_OPTIONS)
    }, [dispatch, navigate])

    let buttonPosition;
    if(window.innerWidth/window.innerHeight < 1){
        buttonPosition = 'bottom-center' 
    }

    return (
        <div className={`top-buttons ${!showSavedDesigns ? "selection " + ( buttonPosition ? buttonPosition : "") : ""}`}>
            <button className="top-button" onClick={goToCart}>
                Go To Cart
            </button>  
            {showSavedDesigns ?
            <button className="top-button" onClick={goToMyDesigns}>
                My Designs
            </button> : 
            <button className="top-button" onClick={handleLogOutPress}>
                Log out
            </button> }

            {logOutPopUp && <PopUpWithMsg 
                optionText={"Log out"}
                onOptionPress={logOut}
                popUpMsg={"Are you sure you want to log out?"}
                closePopUp={()=>{setLogOutPopUp(false)}}
                />}
        </div>
    )

}

export default TopButtons